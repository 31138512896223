<template>
  <div class="upload-content">
    <div class="userinfo">
      <div class="wrap">
        <el-descriptions class="info" title="个人基础信息" :column="2" border>
          <template slot="extra">
            <el-button type="primary" size="small" icon="el-icon-s-tools" @click="dialogFormUserinfo = true">编辑</el-button>
          </template>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>真实姓名
            </template>
            {{ userinfo.realname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>联系电话
            </template>
            {{ userinfo.mobile }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-information"></i>邮寄地址
            </template>
            {{ userinfo.address }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-message"></i>邮政编码
            </template>
            {{ userinfo.PostalCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>开户行
            </template>
            {{ userinfo.bankName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-bank-card"></i>银行卡
            </template>
            {{ userinfo.bankCard }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-postcard"></i>身份证
            </template>
            {{ userinfo.IDCard }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="list">
      <div class="list-item" v-for="(item, index) in list" :key="index">
        <div class="wrap">
          <div class="img-list">
            <div class="hd">获奖数据</div>
            <el-descriptions class="info" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-trophy"></i>奖项名称
                </template>
                {{ item.info.award_title }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-tickets"></i>作品标题
                </template>
                {{ item.info.title }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-notebook-2"></i>作品描述
                </template>
                <el-input class="ipt" size="small" placeholder="请输入作品描述" v-model="item.info.desc"></el-input>
              </el-descriptions-item>
              <!-- <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-location-outline"></i>拍摄地址
                </template>
                <div class="z-flex">
                  <el-input class="ipt" size="small" placeholder="请输入拍摄地址" v-model="item.info.location"></el-input>
                  <el-button class="btn" type="primary" size="small" icon="el-icon-position" @click="showDialogMap(item, index)">打开地图</el-button>
                </div>
              </el-descriptions-item> -->
            </el-descriptions>
            <div class="bd">
              <el-row :gutter="30">
                <el-col :span="item.imgList.length > 1 ? 8 : 24" v-for="(subItem, subIdx) in item.imgList" :key="subIdx">
                  <div :class="['item',item.imgList.length > 1 ? 'item-2' : '']">
                    <div class="img-box">
                      <img class="img" :src="subItem.img"/>
                      <div class="label">
                        <div class="label-text">获奖图</div>
                      </div>
                      <div class="location z-flex" @click="showDialogMap(subItem, subIdx, index)">
                        <i class="el-icon-location-outline"></i>
                        <div v-if="subItem.location" :title="subItem.location">{{ subItem.location }}</div>
                        <div v-else>打开地图选择拍摄地址</div>
                      </div>
                    </div>
                    <div class="img-box img-ori-box">
                      <el-upload
                        class="upload"
                        drag
                        action=""
                        :show-file-list="false"
                        :auto-upload="true"
                        list-type="picture-card"
                        :accept="upAccept"
                        :multiple="upMultiple"
                        :before-upload="handleBeforeUpload"
                        :http-request="(opt) => {handleHttpRequest(opt)}"
                        :on-change="(file, fileList) => {handleChange(file, fileList, index, subIdx)}"
                      >
                        <i class="el-icon-upload el-icon-picture"></i>
                        <div class="el-upload__text">
                          <p>将图片拖到此处或点击<em>上传大图</em></p>
                          <p>支持jpg格式</p>
                        </div>
                        <img class="img-ori" :src="subItem.imgOri" v-if="subItem.imgOri" />
                        <div class="label">
                          <div class="label-text">大图</div>
                        </div>
                      </el-upload>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-group">
      <el-button type="primary" :loading="submitAllBtnLoading" @click="submitAll">保存并关闭</el-button>
    </div>
    <!-- 编辑用户信息 -->
    <el-dialog title="个人基础信息" :visible.sync="dialogFormUserinfo">
      <el-form :model="userinfo" label-width="100px">
        <el-form-item label="真实姓名">
          <el-input v-model="userinfo.realname" clearable></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="userinfo.mobile" clearable disabled></el-input>
        </el-form-item>
        <el-form-item label="邮寄地址">
          <el-input v-model="userinfo.address" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮政编码">
          <el-input v-model="userinfo.PostalCode" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="userinfo.bankName" clearable placeholder="具体到省、市、支行"></el-input>
        </el-form-item>
        <el-form-item label="银行卡">
          <el-input v-model="userinfo.bankCard" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="userinfo.IDCard" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormUserinfo = false">取 消</el-button>
        <el-button type="primary" :loading="submitUserinfoBtnLoading" @click="submitUserinfo">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 地图 -->
    <el-dialog
      title="选择拍摄地点"
      class="dialog-map"
      :visible.sync="dialogMap"
      width="1200px"
    >
      <div v-show="mapLocation!=''" style="padding-bottom:10px;">
        <i class="el-icon-location"></i>当前地址：{{mapLocation}}
        <el-button style="margin-left:10px" type="primary" size="medium" @click="dialogMap = false">确定</el-button>
      </div>
      <div class="map-box" v-if="dialogMap">
        <amap
          ref="amap"
          :zoom="13" 
          async
          :center.sync="centerLocation"
          @click="onMapClick"
          @dragend="onMapDrag"
        >
          <amap-marker v-if="position" :position.sync="position" />
        </amap>
        <div class="search-con">
          <el-input class="search-ipt" placeholder="输入关键词" v-model="mapKeywords" @keyup.enter.native="searchInput" >
            <el-button slot="append" @click="searchInput">搜索</el-button>
          </el-input>
          <div class="address-list" v-if="showAddressList">
						<div class="item" v-for="(item,index) in addressList" :key="index" @click="clickAddrItem(item)">
							<div class="name">{{item.name}}</div>
							<div class="address">{{item.address.length==0 ? item.district : item.address}}</div>
						</div>
					</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import EXIF from "exif-js";
  const dcraw = require('dcraw');
export default {
  components: {

  },
  data() {
    return {
      mid: this.$route.params.id,
      upMultiple: false,
      // upAccept: 'image/jpeg,image/gif,image/png,image/nef,image/tiff,image/raf,image/png',
      upAccept: 'image/jpeg,image/jpg',
      dialogFormUserinfo:false,
      submitUserinfoBtnLoading:false,
      userinfo:{
        realname:'',
        mobile:'',
        address:'',
        PostalCode:'',
        bankName:'',
        bankCard:'',
        IDCard:'',
      },
      list:[],
      itemIndex: 0,
      imgIndex: 0,
      submitAllBtnLoading:false,
      uploadForm:{
        location:'',
        lnglat: '',
      },
      dialogMap:false,
      centerLocation:[119.280403,26.090502],
      position: null,
      mapKeywords:'',
      selAddrItem:{},//选中点位
      showAddressList:false,
      addressList:[],
      amapWebKey: 'e1528e31823f6594189efca741f987c3',
      mapLocation:'',
      mapLnglat:'',
    };
  },
  methods: {
    /**
     * 上传之前的校验
     * @param file
     * @returns {boolean|boolean}
     */
    handleBeforeUpload(file) {
      this.upAccept = this.upAccept.toLowerCase();
      let isImage = this.upAccept.indexOf(file.type.toLowerCase()) >= 0;
      let isLowSize = file.size / 1024 / 1024 < 5;
      let isSize = file.size / 1024 / 1024 < 200;
      if (this.upAccept == '' || this.upAccept == '*') {
        isImage = true;
      }
      if (!isImage) {
        this.$message.error('上传文件只能是图片格式[' + this.upAccept + ']!');
        return false;
      } else if (isLowSize) {
        this.$message.error('上传文件不能小于5MB!');
        return false;
      } else if (!isSize) {
        this.$message.error('上传文件大小不能超过 20MB!');
        return false;
      }
      return isImage && isSize;
    },
    /**
     * 选择文件后生成MD5并且获取oss直传密钥
     * @param file
     * @param filelist
     */
    handleChange(file, fileList, index, subIdx){
      this.itemIndex = index;
      this.imgIndex = subIdx;
      var _self = this;
      if (file.status == 'ready') {
        file.raw.thumburl = file.url;
        //提取缩略图
        var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        var acceptExt = /^(bmp|jpg|jpeg|gif|png|webp)/i;
        if (!acceptExt.test(ext)) {
          //非常规格式，dcraw解析
          var reader = new FileReader();
          reader.onload = (function () {
            return function (e) {
              // Get the image file as a buffer
              var buf = new Uint8Array(e.currentTarget.result);
              // Get the RAW metadata
              // var metadata = dcraw(buf, { verbose: true, identify: true }).split('\n').filter(String);
              // Extract the thumbnail
              var thumbnail = dcraw(buf, { extractThumbnail: true });
              // Create thumbnail
              var blob = new Blob([thumbnail], { type: "image/jpeg" });
              var urlCreator = window.URL || window.webkitURL;
              var imageUrl = urlCreator.createObjectURL(blob);
              file.raw.thumbblob = blob;
              file.raw.thumburl = imageUrl;
              _self.updatePictureCard('src', file.url, file.raw.thumburl);
            };
          })(file.raw);
          reader.readAsArrayBuffer(file.raw);
        }
        // 缩略图预览
        // this.list[index].imgList[subIdx].img = file.url;
        EXIF.getData(file.raw, function(){
          // console.log("--image--", file)
          var dataJson = EXIF.getAllTags(this);
          var latitude = dataJson.GPSLatitude[0]+dataJson.GPSLatitude[1]/60+dataJson.GPSLatitude[2]/60/60;
          var longitude = dataJson.GPSLongitude[0]+dataJson.GPSLongitude[1]/60+dataJson.GPSLongitude[2]/60/60;
          // console.log(longitude, latitude)
          // 经纬度转换  GPS -> 高德
          AMap.plugin('AMap.convertFrom', function () {
            AMap.convertFrom([longitude, latitude], "gps", function (status, result) {
              if (result.info === 'ok') {
                var resLnglat = result.locations[0];
                var lnglat = resLnglat.lng + "," + resLnglat.lat;
                _self.list[_self.itemIndex].imgList[_self.imgIndex].lnglat = lnglat;
                _self.getRegeo(lnglat);
              }
            });
          });
        });
      }
    },
    /**
     * 上传到OSS
     * @param opt
     */
    handleHttpRequest(opt) {
      this.tools.getFileMd5(opt.file, (fileMd5) => {
        //上传到OSS
        opt.file.md5 = fileMd5;
        this.uploadOss(opt.file, (file) => {
          this.list[this.itemIndex].imgList[this.imgIndex].imgOri = file.ossurl;
        })
      });
    },
    //阿里云直传
    uploadOss(file, callback){
      let fileName = file.name;
      this.axios.post("/alioss/params", {name: fileName, md5: file.md5}).then((resa) => {
        resa = resa.data;
        if (resa.code == 200) {
          //阿里云前端直传
          var patt = /^\/\//;
          resa.data.host = (patt.test(resa.data.host) ? 'https:' : '') + resa.data.host;
          var ossform = new FormData();
          ossform.append("name", fileName);
          ossform.append("key", resa.data.key);
          ossform.append("policy", resa.data.policy);
          ossform.append("OSSAccessKeyId", resa.data.OSSAccessKeyId);
          ossform.append("success_action_status", resa.data.success_action_status);
          ossform.append("signature", resa.data.signature);
          ossform.append("callback", resa.data.callback);
          ossform.append("host", resa.data.host);
          ossform.append("file", file, fileName);
          this.axios.post(resa.data.host, ossform, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then(res => {
            if (res.status == 200) {  //上传成功
              file.ossurl = resa.data.host + "/" + resa.data.key;
              callback(file);
              // this.$message.success("上传成功!")
            } else {
              this.$message.error("上传失败，请重新尝试!");
            }
          }).catch(error => {
            console.log("alioss错误: ", error);
          })
        }
      })
    },
    //从已渲染的HTML中替换缩略图URL
    updatePictureCard(action, url, value) {
      var elimgs = document.querySelectorAll(".img-ori");
      for (var i = 0; i < elimgs.length; i++) {
        if (elimgs[i].getAttribute('src') == url) {
          if (action == 'success') {
            var child = document.createElement('div');
            child.append("上传完成");
            elimgs[i].parentElement.insertBefore(child, elimgs[i]);
          } else if (action == 'src') {
            elimgs[i].setAttribute('src', value);
          }
        }
      }
    },
    //保存用户基础信息
    submitUserinfo(){
      this.submitUserinfoBtnLoading = true;
      this.axios.post("/api/editUserInfo",{
          realname: this.userinfo.realname,
          address: this.userinfo.address,
          postcode: this.userinfo.PostalCode,
          bank_name: this.userinfo.bankName,
          bank_card: this.userinfo.bankCard,
          id_card: this.userinfo.IDCard,
      }).then((res) => {
        this.submitUserinfoBtnLoading = false;
        if (res.data.code == 200) {
          this.dialogFormUserinfo = false;
          this.$message.success("保存成功！");
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取个人信息
    getUserInfo(){
      this.axios.get("/api/getUserInfo").then((res) => {
        if (res.data.code == 200) {
          this.userinfo.realname = res.data.data.realname;
          this.userinfo.mobile = res.data.data.mobile;
          this.userinfo.address = res.data.data.address;
          this.userinfo.PostalCode = res.data.data.postcode;
          this.userinfo.bankName = res.data.data.bank_name;
          this.userinfo.bankCard = res.data.data.bank_card;
          this.userinfo.IDCard = res.data.data.id_card;
        }
      });
    },
    // 获取个人信息
    getAward(){
      this.axios.get("/api/getAward?mid=" + this.mid).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data;
        }
      });
    },
    //保存关闭
    submitAll(){
      this.submitAllBtnLoading = true;
      this.axios.post("/api/saveAward",{
          list: this.list,
      }).then((res) => {
        this.submitAllBtnLoading = false;
        if (res.data.code == 200) {
          this.$message.success("保存成功！");
          setTimeout(()=>{
            this.$router.go(-1);
          },3000)
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },
    //打开地图
    showDialogMap(item, imgIndex, index){
      this.dialogMap = true;
      this.imgIndex = imgIndex;
      this.itemIndex = index;
      if (item.lnglat) {
        let lnglat = item.lnglat.split(",");
        this.position = lnglat;
        this.centerLocation = lnglat;
      } else {
        this.position = null;
        this.centerLocation = [119.280403, 26.090502];
        this.mapLocation = '';
      }
    },
    //调用高德地图-逆编码成真实地址
    getRegeo(lnglat){
      let that = this;
      AMap.plugin('AMap.Geocoder', function() {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '全国'
        })
        geocoder.getAddress(lnglat.split(","), function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            that.mapLocation = result.regeocode.formattedAddress;
            that.list[that.itemIndex].imgList[that.imgIndex].location = that.mapLocation;
          }
        })
      })
    },
    //点击地图
    onMapClick(e) {
      // console.log('----onMapClick--',e)
      if (e.lnglat) {
        this.position = [e.lnglat.lng, e.lnglat.lat];
      } else {
        this.position = null;
      }
      let lnglat = e.lnglat.lng + ',' + e.lnglat.lat;
      this.list[this.itemIndex].imgList[this.imgIndex].lnglat = lnglat;
      this.getRegeo(lnglat);
    },
    //拖拽地图
    onMapDrag(e){
      if (e.lnglat) {
        this.position = [e.lnglat.lng, e.lnglat.lat];
      } else {
        this.position = null;
      }
      let lnglat = e.lnglat.lng + ',' + e.lnglat.lat;
      this.list[this.itemIndex].imgList[this.imgIndex].lnglat = lnglat;
      this.getRegeo(lnglat);
    },
    // 选择点位()
    clickAddrItem(item){
      this.addressList.map(item => {
        this.$set(item, 'checked', false);
      });
      // console.log("----", item)
      this.$set(item, 'checked', true);
      this.selAddrItem = item;
      this.position = [item.location.lng, item.location.lat];
      this.centerLocation = [item.location.lng, item.location.lat];
      this.mapKeywords = item.name;
      this.showAddressList = false;
      let lnglat = item.location.lng + ',' + item.location.lat;
      this.list[this.itemIndex].imgList[this.imgIndex].lnglat = lnglat;
      this.getRegeo(lnglat);
      // this.$refs.amap.$map.setCenter(this.center);
    },
    // 搜索点位
    searchInput(e){
      console.log(e);
      AMap.plugin('AMap.AutoComplete', () => {
        var autoOptions = {
          //city 限定城市，默认全国
          city: '全国'
        };
        // 实例化AutoComplete
        var autoComplete= new AMap.AutoComplete(autoOptions);
        // 根据关键字进行搜索
        autoComplete.search(this.mapKeywords, (status, result) => {
          // 搜索成功时，result即是对应的匹配数据
          // console.log(result);
          this.addressList = [];
          for(let i=0; i<result.tips.length; i++) {
            if (result.tips[i].location) {
              this.addressList.push(result.tips[i]);
            }
          }
        })
      });
      this.showAddressList = true;
    },
  },
  created() {
    this.getUserInfo();
    this.getAward();
  },
  mounted() {
    
  },
};
</script>

<style lang="scss" scoped>
.upload-content{
  min-height: calc(100vh - 350px);
  .userinfo{
    margin-top: 40px;
  }
  .list{
    .list-item{
      padding:30px 0;
      &:nth-child(even){
        background: #fff;
      }
    }
  }
  .info{
    ::v-deep .el-descriptions-item__label{
      width: 160px;
      i{
        margin-right: 6px;
        font-size: 16px;
      }
    }
    .btn{
      margin-left: 10px;
    }
  }
  .img-list{
    .info{
      margin-bottom: 30px;
    }
    .hd{
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .item{
      display: flex;
      justify-content:space-between;
      align-items:center;
      margin-bottom: 30px;
      .img-box{
        width: calc(50% - 15px);
        height: 350px;
        overflow: hidden;
        position: relative;
        .img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .label{
          width: 100px;
          height: 100px;
          overflow: hidden;
          position: absolute;
          top: -18px;
          left: -18px;
          .label-text{
            line-height: 18px;
            text-align: center;
            transform: rotate(-45deg);
            position: relative;
            padding: 8px 0;
            left: -32px;
            top: 26px;
            width: 150px;
            background: #F56C6C;
            color: white;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
            letter-spacing: 1px;
          }
        }
        .location{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 30px;
          background: rgba(0,0,0,.5);
          color: #fff;
          font-size: 13px;
          padding: 0 10px;
          box-sizing: border-box;
          cursor: pointer;
          &>i{
            font-size: 15px;
            margin-right: 3px;
          }
          &>div{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &:hover{
            text-decoration: underline;
          }
        }
      }
      ::v-deep .upload{
        width: 100%;
        height: 100%;
        .el-upload{
          width: 100%;
          height: 100%;
          border-radius: 0;
          .img-ori{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .label-text{
            background: #67C23A;
          }
        }
        .el-upload-dragger{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content:center;
          align-items:center;
          line-height: normal;
          border-radius: 0;
          border: none;
          .el-icon-upload{
            margin: 0 0 15px;
          }
        }
      }
      &.item-2{
        flex-direction: column;
        .img-box{
          width: 100%;
          height:250px;
        }
      }
    }
  }
}
.btn-group{
  width: 100%;
  text-align:center;
  padding:30px 0;
  margin-top: 20px;
}
.z-flex{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dialog-map{
  ::v-deep .el-dialog__body{
    padding:20px;
  }
  .map-box{
    width: 100%;
    height:600px;
    position: relative;
    .search-con{
      width:400px;
      height:40px;
      background:#fff;
      position: absolute;
      left: 20px;
      top: 20px;
      .address-list{
        padding: 10px;
        height: 300px;
        overflow-y: auto;
        background: #fff;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        .item{
          border-bottom: 1px solid #ededed;
          padding: 10px 20px 10px 0;
          position: relative;
          cursor: pointer;
          .name{
            font-size: 15px;
            margin-bottom: 5px;
          }
          .address{
            color: #999;
            font-size: 14px;
          }
          ::v-deep .u-icon{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
